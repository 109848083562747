import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import "./ServicesView.css";
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Container, Row } from "reactstrap";

import { getEnvironment } from "../../utilities/env/getEnvironment";

export const ServicesView = () => {
  const params = useParams();

  // State for Active index
  const [activeIndex, setActiveIndex] = React.useState(0);

  // State for Animation
  const [animating, setAnimating] = React.useState(false);

  // Sample items for Carousel
  const items = new Array(14).fill(0).map((_, idx) => {
    return {
      src: getEnvironment().PUBLIC_URL + `/img/services/${idx + 1}.jpg`,
      altText: "",
      className: "carousel-image",
      key: idx
    };
  });

  // Items array length
  const itemLength = items.length - 1;

  // Previous button for Carousel
  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // Next button for Carousel
  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  // Carousel Item Data
  const carouselItemData = items.map((item) => {
    return (
      <CarouselItem key={item.src} onExited={() => setAnimating(false)} onExiting={() => setAnimating(true)}>
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });

  useEffect(() => {
    if (params.scrollTo) {
      document.getElementById(params.scrollTo).scrollIntoView();
    }
  }, []);

  return (
    <div id="services">
      <Container>
        <br />
        <h1 style={{ marginTop: 20, marginBottom: 20, fontSize: 30 }}>Services</h1>
        <br />
        <Carousel previous={previousButton} next={nextButton} activeIndex={activeIndex}>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={(newIndex) => {
              if (animating) return;
              setActiveIndex(newIndex);
            }}
          />
          {carouselItemData}
          <CarouselControl directionText="Prev" direction="prev" onClickHandler={previousButton} />
          <CarouselControl directionText="Next" direction="next" onClickHandler={nextButton} />
        </Carousel>
        <br />
        <hr />
        <br />
        <Row id="personal-instruction">
          <Col xs={12}>
            <h1 style={{ marginBottom: 20 }}>Personal Instruction</h1>
            <ul className="pi-list">
              <li>Learn to safely run your boat</li>
              <li>Understanding your boats electrical systems and proper shore power connections</li>
              <li>Securing your vessel to the dock and/or mooring</li>
              <li>Safety in loading gear, passengers and proper stowage</li>
              <li>Correct fueling procedure</li>
              <li>Preparation for starting your engines</li>
              <li>Navigation and Rules of the Road</li>
              <li>Close quarter maneuvering</li>
              <li>Understanding Charts and Navigation Aids</li>
              <li>Anchoring</li>
              <li>Knot tying and usage</li>
              <li>VHF Procedure and use</li>
              <li>Effective use of Trim Tabs / Ride height</li>
              <li>Personal Watercraft handling and safety</li>
              <li>Trailering technique and procedure</li>
            </ul>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <Col id="private-excursions" xs={12}>
            <h1 style={{ marginBottom: 20 }}>Private Excursions / Captain For Hire</h1>
            <p>
              Have me take you and your guests to your destination – Enjoy the trip and be a guest on your own boat.
            </p>
            <p>Available for day trips and extended excursions.</p>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <Col id="yacht-transport" xs={12}>
            <h1 style={{ marginBottom: 20 }}>Yacht Transport/Delivery</h1>
            <p>
              Whether it is a new boat purchase or a relocation, I will provide you with one way or round-trip delivery.
            </p>
            <p>
              The logistics of relocating your vessel from points A to B can be a challenge. I will coordinate all of
              the details involved in moving your vessel safely and securely.
            </p>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <Col id="safety-inspection" xs={12}>
            <h1 style={{ marginBottom: 20 }}>US Coast Guard Vessel Safety Inspection</h1>
            <p>Vessels passing safety checks are awarded a U.S. Coast Guard / Auxiliary Decal that informs:</p>
            <ul>
              <li>Coast Guard / Auxiliary</li>
              <li>Harbor Patrol</li>
              <li>Sheriff's & Police</li>
              <li>other boating law-enforcement & safety agency's</li>
            </ul>
            <p>
              That your boat was in full compliance with all Federal and State boating laws during a safety check for
              that year. Every Vessel Safety Check is 100% Free of charge!
            </p>
            <p>What Type of Items Are Checked?</p>
            <ul>
              <li>Life Jackets</li>
              <li>Registration and numbering</li>
              <li>Navigation lights</li>
              <li>Ventilation</li>
              <li>Fire extinguishers</li>
              <li>Distress signals (flares, horn, etc.)</li>
              <li>Battery cover and connections</li>
            </ul>
            <p>
              All of these items are currently required by state and federal laws and, if missing or non-operating, can
              result in a citation if your vessel is inspected by the Coast Guard
            </p>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};
