import axios from "axios";
import mapboxgl from "mapbox-gl";

import React, { useEffect, useRef, useState } from "react";

import { Button, Container, Form, FormGroup, Input, Label, Spinner } from "reactstrap";

import "./ContactView.css";

export const ContactView = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng] = useState(-73.2328334);
  const [lat] = useState(40.7850726);
  const [zoom] = useState(7);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [boatType, setBoatType] = useState("N/A");
  const [serviceType, setServiceType] = useState("N/A");
  const [notes, setNotes] = useState("");

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    (map.current as any) = new mapboxgl.Map({
      container: mapContainer.current as any,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
      interactive: false
    });
  });

  const onNameUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const onEmailUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const onPhoneUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    setPhone(event.currentTarget.value);
  };

  const onNotesUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    setNotes(event.currentTarget.value);
  };

  const onBoatTypeUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    setBoatType(event.currentTarget.value);
  };

  const onServiceTypeUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    setServiceType(event.currentTarget.value);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (submitting) return;

    try {
      const missingFields = [name, email, phone, boatType, serviceType].filter((val) => !val);
      if (missingFields.length > 0) {
        alert("Please fill out all fields on the form");
        return;
      }

      setSubmitting(true);

      await axios.post("/api/contact", {
        name,
        email,
        phone,
        boatType,
        serviceType,
        notes
      });

      setName("");
      setEmail("");
      setPhone("");
      setServiceType("N/A");
      setBoatType("N/A");
      setNotes("");

      alert("Form submitted successfully! Seth will be in touch.");

      setTimeout(() => window.location.reload(), 3000);
    } catch (error) {
      alert("Form could not be submitted, please email Seth instead.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div id="contact">
      <Container>
        <br />
        <h1 style={{ marginTop: 20, marginBottom: 20, fontSize: 30 }}>Contact Me</h1>
        <p>Please call or use this form to schedule a private lesson or arrange another service.</p>
        <p>
          <a href="mailto:seth@captainsethg.com">seth@captainsethg.com</a>
          <br />
          <a href="tel:5167799958">516.779.9958</a>
        </p>
        <hr />
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input value={name} onChange={onNameUpdate} id="name" name="name" placeholder="John Smith" type="text" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              value={email}
              onChange={onEmailUpdate}
              id="email"
              name="email"
              placeholder="johnsmith@aol.com"
              type="email"
            />
          </FormGroup>
          <FormGroup>
            <Label for="phoneNumber">Phone Number</Label>
            <Input
              value={phone}
              onChange={onPhoneUpdate}
              id="phoneNumber"
              name="phoneNumber"
              placeholder="555-555-5555"
              type="tel"
            />
          </FormGroup>
          <FormGroup>
            <Label for="boatType">Type of Boat</Label>
            <Input value={boatType} onChange={onBoatTypeUpdate} id="boatType" name="boatType" type="select">
              <option>N/A</option>
              <option>Single-Engine Center Console</option>
              <option>Multi-Engine Center Console</option>
              <option>Mid-Range Cruiser</option>
              <option>Yacht</option>
              <option>Other</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="serviceType">Desired Service</Label>
            <Input value={serviceType} onChange={onServiceTypeUpdate} id="serviceType" name="serviceType" type="select">
              <option>N/A</option>
              <option>Personal Instruction</option>
              <option>Private Excursions</option>
              <option>Captain For Hire</option>
              <option>Yacht Transport/Delivery</option>
              <option>Other</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="extraInfo">Anything else I should know?</Label>
            <Input value={notes} onChange={onNotesUpdate} id="extraInfo" name="text" type="textarea" />
          </FormGroup>
          <div className="submit-group">
            <Button disabled={submitting}>Submit</Button>
            {submitting && <Spinner style={{ marginLeft: 10 }} />}
          </div>
        </Form>
      </Container>
      <div style={{ marginTop: 100, marginBottom: 40, height: 400 }} ref={mapContainer} className="map-container" />
    </div>
  );
};
