import React from "react";

import "./HomeView.css";

import { Link } from "react-router-dom";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";

import { getEnvironment } from "../../utilities/env/getEnvironment";

export const HomeView = () => {
  return (
    <div id="home">
      <div className="bg-wrapper" style={{ background: `url("${getEnvironment().PUBLIC_URL + "/splash.jpg"}")` }}>
        <div className="bg">
          <div className="info">
            <h1>Experienced Captain & Instructor</h1>
            <h2>Captain Seth R. Goldstein</h2>
            <br />
            <img className="uscg" src={getEnvironment().PUBLIC_URL + "/uscg.png"} />
          </div>
        </div>
      </div>
      <Container style={{ marginTop: 40 }}>
        <Row>
          <Col xs="12" md="3">
            <div className="headshot-wrapper">
              <img className="headshot" src={getEnvironment().PUBLIC_URL + "/headshot.png"} />
            </div>
          </Col>
          <Col xs="12" md="9">
            <h5>New to Power Boating?</h5>
            <h5>Looking to increase your confidence in docking and close quarter maneuvering?</h5>
            <br />
            <p>
              From the new boat owner to the seasoned Captain, you will gain knowledge, experience and develop
              confidence at the helm. I will teach you at your own pace.
            </p>
            <p>
              I am a 25 year member of the United States Power Squadrons, and have been teaching their education courses
              throughout my membership. I grew up on the water and I am a 200 gross-ton Master Captain. I understand the
              absolute dynamics of how your boat moves in the water and can explain it to you in an approachable manner.
              I am an expert in close-quarter maneuvering and can convey a breadth of techniques to the recreational
              boater.
            </p>
            <p>
              Whether your boat is a single-engine center-console, a mid-range cruiser, or an elegant yacht, your
              expectations and goals will be exceeded. The combination of my knowledge of boat handling, my patience and
              my approachable teaching style is the winning formula to your success. My goal is to have you be fully
              capable at the helm of your vessel. Upon completion of our training, you will find that you have gained
              the confidence and skills to safely and decisively operate your vessel.
            </p>
            <p>Some topics we may cover:</p>
            <ul>
              <li>Boat handling skills and docking</li>
              <li>Rules of the road</li>
              <li>Reading and understanding charts</li>
              <li>Navigation</li>
              <li>Marine Weather</li>
              <li>Tides and Currents</li>
            </ul>
            <p>Don’t let the two best days of your boat ownership be the days that you buy and sell your boat.</p>
            <p>Call Captain Seth Goldstein today!</p>
          </Col>
        </Row>
      </Container>
      <div className="services-offered-wrapper">
        <Container>
          <h3>Services Offered</h3>
          <Row className="services-offered">
            <Col className="services-offered-entry" id="personal-instruction" xs="12" md="3">
              <Link className="services-link" to="/services/personal-instruction">
                Personal Instruction
              </Link>
              <Link to="/services/personal-instruction">
                <img className="services-offered-img" src={getEnvironment().PUBLIC_URL + "/private-instruction.png"} />
              </Link>
            </Col>
            <Col className="services-offered-entry" id="private-excursions" xs="12" md="3">
              <Link className="services-link" to="/services/private-excursions">
                Captain For Hire / Private Excursions
              </Link>
              <Link to="/services/private-excursions">
                <img className="services-offered-img" src={getEnvironment().PUBLIC_URL + "/excursion.png"} />
              </Link>
            </Col>
            {/*<Col className="services-offered-entry" id="captain-for-hire" xs="12" md="3">*/}
            {/*  <Link className="services-link" to="/services/private-excursions">*/}
            {/*    Captain For Hire*/}
            {/*  </Link>*/}
            {/*  <Link to="/services/private-excursions">*/}
            {/*    <img className="services-offered-img" src={getEnvironment().PUBLIC_URL + "/captain-for-hire.png"} />*/}
            {/*  </Link>*/}
            {/*</Col>*/}
            <Col className="services-offered-entry" id="yacht-delivery" xs="12" md="3">
              <Link className="services-link" to="/services/yacht-transport">
                Yacht Transport/Delivery
              </Link>
              <Link to="/services/yacht-transport">
                <img className="services-offered-img" src={getEnvironment().PUBLIC_URL + "/yacht-delivery.png"} />
              </Link>
            </Col>
            <Col className="services-offered-entry" id="yacht-delivery" xs="12" md="3">
              <Link className="services-link" to="/services/safety-inspection">
                US Coast Guard Vessel Safety Inspection
              </Link>
              <Link to="/services/safety-inspection">
                <img className="services-offered-img" src={getEnvironment().PUBLIC_URL + "/safety-inspection.jpg"} />
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row style={{ marginTop: 10, marginBottom: 50 }}>
          <Col xs="12">
            <h5 style={{ marginBottom: 45, marginTop: 20 }}>Professional Licenses/Certifications</h5>
            <ListGroup>
              <ListGroupItem>USCG 200 (GRT) Master Captain - Reference #3557615</ListGroupItem>
              <ListGroupItem>United States Power Squadron Certification</ListGroupItem>
              <ListGroupItem>Certified Vessel Safety Examiner</ListGroupItem>
              <ListGroupItem>Commercial Assist Towing Endorsement</ListGroupItem>
              <ListGroupItem>First Aid/CPR Certified</ListGroupItem>
              <ListGroupItem>PADI Open Water Diver</ListGroupItem>
              <ListGroupItem>New York State Certified Safe Boating Instructor</ListGroupItem>
              <ListGroupItem>FCC Radio Operator</ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
