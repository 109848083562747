import React from "react";

import "./Footer.css";
import { Col, Container, Row } from "reactstrap";
import { getEnvironment } from "../../utilities/env/getEnvironment";

export const Footer = () => {
  return (
    <div className="footer">
      <Container className="footer-inner">
        <Row>
          <Col xs={12} md={4}>
            © Copyright Shappiro LLC {new Date().getFullYear()}
          </Col>
          <Col xs={12} md={4}></Col>
          <Col style={{ textAlign: "right" }} xs={12} md={4}>
            <p>630 Shore Road</p>
            <p>Long Beach, NY 11561</p>
            <p style={{ marginTop: 10 }}>516.779.9958</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
