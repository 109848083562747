import React from "react";

import { Route, BrowserRouter, Routes } from "react-router-dom";

import { Navigation } from "./components/navigation/Navigation";
import { Footer } from "./components/footer/Footer";

import { HomeView } from "./views/home/HomeView";
import { ServicesView } from "./views/about/ServicesView";
import { ContactView } from "./views/contact/ContactView";

export const Application = () => {
  return (
    <BrowserRouter>
      <Navigation />
      <div className="page-wrapper">
        <Routes>
          <Route path="/services/:scrollTo" element={<ServicesView />} />
          <Route path="/services" element={<ServicesView />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/" element={<HomeView />} />
        </Routes>
        <div className="push" />
      </div>
      <Footer />
    </BrowserRouter>
  );
};
