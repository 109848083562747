import React from "react";

import { Link } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";

import "./Navigation.css";

export const Navigation = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Navbar color="light" expand="md" light>
      <Link className="nav-link brand" to="/">
        Captain Seth G.
      </Link>
      <NavbarToggler
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="me-auto" navbar>
          <Link className="nav-link" to="/services">
            Services
          </Link>
          <Link className="nav-link" to="/contact">
            Contact
          </Link>
        </Nav>
      </Collapse>
    </Navbar>
  );
};
