import React from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";

import { Application } from "./Application";
import { getEnvironment } from "./utilities/env/getEnvironment";

import "bootstrap/dist/css/bootstrap.min.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "./Application.css";

const { REACT_APP_MAPBOX_TOKEN } = getEnvironment();

/* Set mapbox public access token and fix webpack production issue */
/* https://stackoverflow.com/questions/65802002/mapbox-production-error-in-console-uncaught-referenceerror-y-is-not-defined */
mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById("root")
);
